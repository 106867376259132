import React from 'react';
import Drawer from '../../components/Mobile/Drawer';
import Header from '../Sections/Header';
import useToggle from '../../Hooks/useToggle';
import Hero from '../Sections/Hero';
import About from '../Sections/About';
import WhySelect from '../Sections/WhySelect';
import Mission from '../Sections/Mission';
import Contact from '../Sections/Contact';
import Footer from '../Sections/Footer';

import BackToTop from '../../components/BackToTop';

const Home = () => {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Header action={drawerAction.toggle} />
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Hero />
            <About />
            <Mission />
            <WhySelect />
            <Contact />
            <Footer />
            <BackToTop />
        </>
    );
};

export default Home;
