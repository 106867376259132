import React from 'react';
import serviceThumb from '../../assets/img/Quem_Somos.png';

function About() {
    return (
        <>
            <section id="about" className="appie-services-2-area appie-services-8-area pt-90 pb-55">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 p-20">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Quem somos</h3>
                                <p>A financeira que transforma negócios!</p>
                            </div>
                            <div>
                                <p style={{ fontSize: '1.2rem', fontWeight: '300' }}>
                                    Somos a All Just, uma financeira inovadora e comprometida com o
                                    crescimento dos nossos clientes. Nossa missão é oferecer
                                    soluções financeiras eficientes e personalizadas, impulsionando
                                    o desenvolvimento de empresas e empreendedores de todos os
                                    portes.
                                </p>
                                <br />
                                <p style={{ fontSize: '1.2rem', fontWeight: '300' }}>
                                    Nosso time de especialistas trabalha incansavelmente para criar
                                    estratégias sólidas e garantir que nossos clientes alcancem seus
                                    objetivos financeiros. Valorizamos a transparência, a ética e a
                                    responsabilidade em todas as nossas ações, pois acreditamos que
                                    esses são os pilares fundamentais para uma relação de confiança
                                    com nossos parceiros.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <img src={serviceThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
