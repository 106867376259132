import React from 'react';
import valores from '../../assets/img/icon/valores.png';
import missao from '../../assets/img/icon/missao.png';
import visao from '../../assets/img/icon/visao.png';

function Mission() {
    return (
        <>
            <section className="appie-service-area appie-service-3-area pt-50 pb-50" id="mission">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Missão, Visão e Valores</h3>
                                <p style={{ color: 'white' }}>
                                    A financeira que entende você e seu negócio!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={missao} alt="" />
                                </div>
                                <h4 className="appie-title">Missão</h4>
                                <p style={{ color: 'white' }}>
                                    Promover o crescimento sustentável e o desenvolvimento de
                                    empresas e empreendedores, oferecendo soluções financeiras
                                    inovadoras e personalizadas, com foco na satisfação e sucesso
                                    dos nossos clientes.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={visao} alt="" />
                                </div>
                                <h4 className="appie-title">Visão</h4>
                                <p style={{ color: 'white' }}>
                                    Ser referência no mercado financeiro, reconhecida pela
                                    excelência no atendimento, inovação e comprometimento com a
                                    transformação positiva dos negócios dos nossos clientes e
                                    parceiros.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={valores} alt="" />
                                </div>
                                <h4 className="appie-title">Valores</h4>
                                <p style={{ color: 'white' }}>
                                    Agimos com clareza, honestidade e abertura em todas as nossas
                                    relações. Pautamos nossas ações e decisões na integridade e
                                    responsabilidade, respeitando as leis, normas e regulamentações
                                    do setor financeiro.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Mission;
