import React from 'react';

function Contact() {
    return (
        <>
            <section className="contact-section" id="contact_form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Fale conosco</h3>
                                <p>
                                    Tem alguma dúvida sobre os nossos serviços? Entre em contato
                                    conosco.
                                </p>
                                <div className="single-info">
                                    <h5>Endereço</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        Rua Florida 1703, Cjto 81
                                        <br /> Brookling SP.
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Telefone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        (11) 2776-9269
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        contato@alljust.com.br
                                        <br />
                                        comercial@alljust.com.br
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Siga-nos</h5>
                                    <a className="fac" href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>

                                    <a className="you" href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a className="lin" href="#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Formulário de contato</h4>
                                <p>Preencha dos dados abaixo retornaremos por e-mail</p>
                                <form action="#" method="post" className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="f-name" placeholder="Nome" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="l-name" placeholder="Sobrenome" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" name="email" placeholder="Email" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="number" name="phone" placeholder="Telefone" />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="suject" placeholder="Assunto" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="Como posso ajudar?"
                                        ></textarea>
                                    </div>

                                    <div className="col-md-12 text-right">
                                        <input
                                            type="submit"
                                            name="submit"
                                            value="Enivar mensagem"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.912765697815!2d-46.6944361!3d-23.607461300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce50cb5428da9f%3A0xcb397a53d488a39e!2sR.%20Fl%C3%B3rida%2C%201703%20-%20Brooklin%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004565-001!5e0!3m2!1spt-BR!2sbr!4v1680218952413!5m2!1spt-BR!2sbr"
                ></iframe>
            </div>
        </>
    );
}

export default Contact;
