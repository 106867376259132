import React from 'react';
import featureThumb from '../../assets/img/Por-que-escolher-a-All-Just.png';

function WhySelect() {
    return (
        <>
            <section className="appie-features-area-2 pt-90 pb-100" id="products">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">Por que escolher a All Just?</h3>
                                <p>
                                    Junte-se a nós e descubra como a All Just pode impulsionar seu
                                    sucesso financeiro!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="appie-features-box-item">
                                    <h4 className="title">Antecipação de Recebíveis</h4>
                                    <p>
                                        Melhore seu fluxo de caixa antecipando recebimentos futuros
                                    </p>
                                </div>
                                <div className="appie-features-box-item item-2">
                                    <h4 className="title">Factoring All Just</h4>
                                    <p>Venda seus créditos comerciais e receba à vista</p>
                                </div>
                                <div className="appie-features-box-item">
                                    <h4 className="title">Crédito rápido</h4>
                                    <p>Menos burocracia e mais agilidade.</p>
                                </div>
                                <div className="appie-features-box-item item-2">
                                    <h4 className="title">Segurança financeira</h4>
                                    <p>Trabalhe só com recursos que já estão disponíveis</p>
                                </div>
                                <div className="appie-features-box-item">
                                    <h4 className="title">Valor do crédito</h4>
                                    <p>Mais barato do que o cheque especial!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WhySelect;
