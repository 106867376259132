import React from 'react';
import logo from '../../assets/img/logo_white.png';

function Footer() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="logo">
                                <div className="col-lg-12 col-md-12 text-center">
                                    <a href="#" style={{ width: '20%' }}>
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <div className="copyright-text text-center">
                                    <p style={{ color: 'white' }}>
                                        Copyright © 2023. Todos os direitos reservados.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;
