import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo_black.png';

function Drawer({ drawer, action }) {
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li>
                                            <a href="#">Inicio</a>
                                        </li>
                                        <li>
                                            <Link to="#quem-somos">
                                                <AnchorLink
                                                    style={{ color: '#0e1133' }}
                                                    href="#about"
                                                >
                                                    Quem somos
                                                </AnchorLink>
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <AnchorLink
                                                    style={{ color: '#0e1133' }}
                                                    href="#mission"
                                                >
                                                    Missão
                                                </AnchorLink>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <AnchorLink
                                                    style={{ color: '#0e1133' }}
                                                    href="#products"
                                                >
                                                    Produtos
                                                </AnchorLink>
                                            </a>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <AnchorLink
                                                    style={{ color: '#0e1133' }}
                                                    href="#contact_form"
                                                >
                                                    Contato
                                                </AnchorLink>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a href="$">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="$">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-envelope"></i>{' '}
                                                contato@alljust.com.br
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-phone"></i> (11) 2776-9269
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt"></i> Rua
                                                Florida 1703, Cjto 81 Brookling SP.
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
