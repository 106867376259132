import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="#">Inicio</a>
                </li>
                <li>
                    <Link to="#quem-somos">
                        <AnchorLink style={{ color: '#0e1133' }} href="#about">
                            Quem somos
                        </AnchorLink>
                    </Link>
                </li>
                <li>
                    <a href="#">
                        <AnchorLink style={{ color: '#0e1133' }} href="#mission">
                            Missão
                        </AnchorLink>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <AnchorLink style={{ color: '#0e1133' }} href="#products">
                            Produtos
                        </AnchorLink>
                    </a>
                </li>
                <li>
                    <Link to="#">
                        <AnchorLink style={{ color: '#0e1133' }} href="#contact_form">
                            Contato
                        </AnchorLink>
                    </Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
