import React from 'react';

function Hero() {
    return (
        <>
            <section className="appie-hero-area appie-hero-4-area" style={{ paddingBottom: 200 }}>
                <div className="container">
                    <div className="row align-items-center mt-40">
                        <div className="col-lg-7">
                            <div className="appie-hero-content appie-hero-content-4">
                                <span>- O caminho certo para sua prosperidade!</span>
                                <h1 className="appie-title">
                                    Conquiste seu futuro financeiro com a gente!
                                </h1>
                                <p>
                                    <span
                                        role="img"
                                        aria-label="point"
                                        style={{ color: '#00d1b3' }}
                                    >
                                        •
                                    </span>{' '}
                                    Controle seu dinheiro de maneira inteligente
                                </p>
                                <p>
                                    <span
                                        role="img"
                                        aria-label="point"
                                        style={{ color: '#00d1b3' }}
                                    >
                                        •
                                    </span>{' '}
                                    Antecipe com segurança e confiabilidade
                                </p>
                                <p>
                                    <span
                                        role="img"
                                        aria-label="point"
                                        style={{ color: '#00d1b3' }}
                                    >
                                        •
                                    </span>{' '}
                                    Tire suas dúvidas com nosso time!
                                </p>
                                <a className="main-btn" href="#">
                                    Saiba mais
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
